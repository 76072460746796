.setting_wrapper_main_container {
  padding: 20px 32px;
}
.settings_wrapper_header_container {
  background: var(--theme-table-header);
  padding: 20px 32px;
}
.settings_wrapper_container {
  overflow: hidden;
  height: 100%;
}
