.d-block {
  display: block !important;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//line height utilities
.line-height-normal {
  line-height: normal !important;
}

// Flex Utilities

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.align-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-self-start {
  align-self: flex-start;
}

.align-start {
  align-items: flex-start;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-column {
  flex-direction: column;
}

// Spacers

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-30 {
  margin-right: 30px !important;
}


.pl-8 {
  padding-left: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.upper-case {
  text-transform: uppercase !important;
}

.auto-margin {
  margin: auto !important;
}

.marginlr-5 {
  margin: 0px 5em !important;
}

.mr-auto {
  margin-right: auto;
}

.border-none {
  border: none !important;
}

.mg_bottom_20 {
  margin-bottom: 20px !important;
}

.mg_bottom_10 {
  margin-bottom: 10px !important;
}

.padding-0 {
  padding: 0 !important;
}

.padd-btm-24 {
  padding-bottom: 24px;
}

.padd-btm-5 {
  padding-bottom: 5px;
}

.padd-y-14 {
  padding: 14px 0px !important;
}

.padd-top-5 {
  padding-top: 5px
}

.padding-x0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.padding-y0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mg_left_8 {
  margin-left: 8px !important;
}

.mg_bottom_24 {
  margin-bottom: 24px !important;
}

.mg_bottom_30 {
  margin-bottom: 30px !important;
}

.mg_bottom_40 {
  margin-bottom: 40px !important;
}

.mg_top_20 {
  margin-top: 20px !important;
}

.mg_top_24 {
  margin-top: 24px !important;
}


.mg_top_40 {
  margin-top: 40px !important;
}

.mg_top_32 {
  margin-top: 32px !important;
}

.mg_top_100 {
  margin-top: 100px !important;
}

.mg_bottom_32 {
  margin-bottom: 40px !important;
}

.mg_top_44 {
  margin-top: 44px !important;
}

.mg_left_16 {
  margin-left: 16px !important;
}

.mg_bottom_8 {
  margin-bottom: 8px !important;
}

.mg_bottom_16 {
  margin-bottom: 16px !important;
}

.mg_top_16 {
  margin-top: 16px !important;
}

.gutter {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}

// Themes

.pink-bg {
  background: #fff4f3 !important;
}

.light-pink-bg {
  background: #fff4f366 !important;
}

.white-bg {
  background: #ffffff !important;
}

.theme_color_orange,
.theme-highlight-color {
  color: var(--theme-highlight-color) !important;
}

.theme_bg_color_orange {
  background-color: var(--theme-highlight-color-bg) !important;
}

.theme_color_blue {
  color: var(--theme-secondary-main) !important;
}

.color_error {
  color: var(--theme-error) !important;
}

.color_success {
  color: var(--theme-success) !important;
}

.color_white {
  color: var(--theme-cotrast-color) !important;
}

// Layout

.displayNone {
  display: none !important;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.bank-card {
  padding: "0px 24px" !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.table_view .MuiTableCell-stickyHeader {
  background-color: inherit !important;
}

.table_view .MuiTableCell-root {
  border-bottom: none !important;
}

.border_radius_left_right_20 {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.user_management_header {
  padding: 24px;
}

.search_container {
  margin: 0 !important;
  border: none;
  padding: 0 24px;
}

.search_field {
  width: calc(100% - 330px);
}

.fullHeight {
  height: 100%;
}

.fullWidth {
  width: 100%;
}

.stripe_action_buttons {
  height: 60px !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}

.text_align_center {
  text-align: center !important;
}

.align-baseline {
  align-items: baseline;
}

.text_align_end {
  text-align: end !important;
}

.min_width_fit_content {
  min-width: fit-content !important;
}

.bold {
  font-weight: 500 !important;
}

.bolder {
  font-weight: 700 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.bold_unset {
  font-weight: unset !important;
}

.cursor_pointer {
  cursor: pointer;
}

.cursor_nodrop {
  cursor: no-drop;
}

.pointer-events-none {
  pointer-events: none;
}

.verticalTop {
  vertical-align: top;
}

.maxWidthFull {
  max-width: 100%;
}

.login_divider {
  display: inline-block;
  width: 120px;
  height: 6px !important;
  background: #2b92d0 !important;
  margin: 20px 0 0 !important;
}

.dropdown-loader {
  position: absolute;
  top: 30px;
  right: 45px;
}

.dropdown-loader-sp1 {
  top: 18px;
}

.dropdown-loader-sp2 {
  top: 22px;
}

.mTop30 {
  margin-top: 30px !important;
}

.text-secondary {
  color: var(--theme-text-secondary);
}

.disable-form {
  cursor: wait;

  * {
    pointer-events: none;
  }
}

.inf-symbol {
  font-size: 1.25rem;
}

.separator {
  color: var(--theme-primary-main) !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.pink-color {
  color: var(--theme-highlight-color) !important;
}